import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from '../layouts/layout';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { ArrowLeftIcon } from '@heroicons/react/solid';
// import { useMobileCategories } from '../hooks/useMobileCategories';

const TestTemplate = ({ data }) => {
  const article = data.strapiArticle;
  // const mobileCategories = useMobileCategories();

  const seo = {
    metaTitle: article.title,
    metaDescription: article.description,
    shareImage: article.image,
    article: true,
  };

  return (
    <Layout seo={seo}>
      <div>
        <Link to={'/minor'} className='flex items-center'>
          <ArrowLeftIcon className='w-6 h-6 text-white' />
          <span className='ml-6 text-xl'>My minor</span>
        </Link>
        <div className='grid mt-12'>
          <GatsbyImage
            style={{
              gridArea: '1/1',
            }}
            alt={`Picture for ${article.title} article`}
            image={getImage(article.image.localFile)}
            layout='fullWidth'
          />
          <div
            style={{
              // By using the same grid area for both, they are stacked on top of each other
              gridArea: '1/1',
              position: 'relative',
              // This centers the other elements inside the hero component
              placeItems: 'center',
              display: 'grid',
            }}
          >
            <h1 className='font-black text-8xl'>{article.title}</h1>
          </div>
        </div>

        <section className='mt-8'>
          {article.sub_articles &&
            article.sub_articles.map((subArticle, i) => {
              return (
                <div key={i} className='mb-8'>
                  <ReactMarkdown
                    className='prose prose-lg text-white prose-indigo max-w-none'
                    components={{
                      code({ node, inline, className, children, ...props }) {
                        const match = /language-(\w+)/.exec(className || '');
                        return !inline && match ? (
                          <SyntaxHighlighter
                            children={String(children).replace(/\n$/, '')}
                            style={vscDarkPlus}
                            language={match[1]}
                            PreTag='div'
                            wrapLongLines={true}
                            {...props}
                          />
                        ) : (
                          <code className={className} {...props}>
                            {children}
                          </code>
                        );
                      },
                    }}
                  >
                    {subArticle.content}
                  </ReactMarkdown>
                </div>
              );
            })}
        </section>

        {article.author && (
          <footer className='flex items-center'>
            {article.author?.picture && (
              <GatsbyImage
                image={article.author.picture.localFile.childImageSharp.gatsbyImageData}
                alt={`Picture of ${article.author.name}`}
                className='mr-6 rounded-full'
              />
            )}
            <div>
              <p>By {article.author.name}</p>
            </div>
          </footer>
        )}
      </div>
    </Layout>
  );
};

export default TestTemplate;

export const ArticleQuery = graphql`
  query ArticleQuery($slug: String!) {
    strapiArticle(category: { slug: { eq: $slug } }) {
      strapiId
      title
      category {
        slug
      }
      image {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      sub_articles {
        title
        mobile_category {
          name
        }
        content
      }
      author {
        name
        picture {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 50, height: 50)
            }
          }
        }
      }
    }
  }
`;
